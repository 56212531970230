@import "src/styles/partials/media";

section.wrapper {
  display: flex;

  div.streams {
    @apply bg-zinc-900;
    width: 100%;
    display: grid;
    position: relative;
    grid-template-rows: [header] var(--header-height) [content] calc(100vh - var(--header-height) - var(--footer-height)) [footer] var(--footer-height);
    grid-template-columns: [first] 1fr;
  }

  aside.chat {
    height: calc(100vh - var(--footer-height));
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;

    @include lg {
      height: 100vh;
      position: static;
    }

    &[data-pinned="true"] {
      height: calc(100vh - var(--footer-height));
      position: fixed;
      right: 0;
      top: 0;
    }
  }
}
