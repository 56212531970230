@import "src/styles/partials/media";

.wrapper {
  align-items: center;
  display: flex;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  margin: 0 auto;
  position: relative;
  width: calc(100% - 30px);

  @include sm {
    width: calc(100% - 60px);
  }

  .hostOnly, .peer {
    @apply bg-zinc-700;
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .hostOnly {
  }

  .peer {
    @apply bg-zinc-800;
  }

  .host {
    @apply bg-zinc-700;
    bottom: 10px;
    height: 150px;
    position: absolute;
    right: 10px;
    width: 200px;
    z-index: 1;

    @include sm {
      height: 225px;
      width: 300px;
    }
  }
}
