@import "src/styles/partials/media";

.chat {
  height: calc(100vh - var(--header-height) - var(--footer-height));
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  @include lg {
    height: 100vh;
    position: static;
  }
}
