@import "src/styles/partials/media";

div.wrapper {
  display: grid;
  grid-template-rows: [row] 1fr;
  height: calc(100vh - var(--header-height) - var(--footer-height));

  > div.pinned {
    display: grid;
    height: calc(100vh - var(--header-height) - var(--footer-height));
    grid-area: peers;
    @apply px-6;
  }

  > div.peers {
    display: block;
    overflow-y: auto;
    @apply px-6;

    @include md {
      grid-area: peers;
      display: grid;
    }

    > div {
      display: grid;
      @apply gap-3;
      @apply grid-cols-1;

      @include md {
        &[data-cols="1"] {
          @apply grid-cols-1;
        }

        &[data-cols="2"] {
          @apply grid-cols-2;
        }

        &[data-cols="3"] {
          @apply grid-cols-3;
        }

        &[data-cols="4"] {
          @apply grid-cols-4;
        }

        &[data-cols="5"] {
          @apply grid-cols-5;
        }

        &[data-cols="6"] {
          @apply grid-cols-6;
        }

        &[data-cols="7"] {
          @apply grid-cols-7;
        }

        &[data-cols="8"] {
          @apply grid-cols-8;
        }

        &[data-cols="9"] {
          @apply grid-cols-9;
        }

        &[data-cols="10"] {
          @apply grid-cols-10;
        }

        &[data-cols="11"] {
          @apply grid-cols-11;
        }

        &[data-cols="12"] {
          @apply grid-cols-12;
        }

        &[data-cols="13"] {
          @apply grid-cols-13;
        }

        &[data-cols="14"] {
          @apply grid-cols-14;
        }

        &[data-cols="15"] {
          @apply grid-cols-15;
        }

        &[data-cols="16"] {
          @apply grid-cols-16;
        }

        &[data-rows="1"] {
          @apply grid-rows-1;
        }

        &[data-rows="2"] {
          @apply grid-rows-2;
        }

        &[data-rows="3"] {
          @apply grid-rows-3;
        }

        &[data-rows="4"] {
          @apply grid-rows-4;
        }

        &[data-rows="5"] {
          @apply grid-rows-5;
        }

        &[data-rows="6"] {
          @apply grid-rows-6;
        }

        &[data-rows="7"] {
          @apply grid-rows-7;
        }

        &[data-rows="8"] {
          @apply grid-rows-8;
        }

        &[data-rows="9"] {
          @apply grid-rows-9;
        }

        &[data-rows="10"] {
          @apply grid-rows-10;
        }

        &[data-rows="11"] {
          @apply grid-rows-11;
        }

        &[data-rows="12"] {
          @apply grid-rows-12;
        }

        &[data-rows="13"] {
          @apply grid-rows-13;
        }

        &[data-rows="14"] {
          @apply grid-rows-14;
        }

        &[data-rows="15"] {
          @apply grid-rows-15;
        }

        &[data-rows="16"] {
          @apply grid-rows-16;
        }
      }
    }
  }

  > div.list {
    display: block;
    height: calc(100vh - var(--header-height) - var(--footer-height));
    grid-area: list;
    overflow-y: auto;
    @apply pr-6;

    > div {
      display: grid;
      @apply gap-3;
    }
  }

  &[data-pinned="false"] {
    grid-template-columns: [peers] 1fr;

    > div.pinned {
      display: none;
      @apply px-0;
    }

    > div.peers {
      height: calc(100vh - var(--header-height) - var(--footer-height));
    }

    > div.list {
      display: none;
      @apply px-0;
    }
  }

  &[data-pinned="true"] {
    grid-template-columns: [peers] 1fr;

    @include md {
      grid-template-columns: [peers] minmax(0, 1fr) [list] 12rem;
    }

    @include lg {
      grid-template-columns: [peers] minmax(0, 1fr) [list] 20rem;
    }

    @include xl {
      grid-template-columns: [peers] minmax(0, 1fr) [list] 26rem;
    }

    @include xxl {
      grid-template-columns: [peers] minmax(0, 1fr) [list] 32rem;
    }

    > div.pinned {
      display: none;

      @include md {
        display: grid;
      }
    }

    > div.peers {
      display: block;

      @include md {
        display: none;
      }
    }

    > div.list {
      display: none;

      @include md {
        display: block;
      }
    }
  }
}
