@import "src/styles/partials/media";

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  padding: 25px 0;

  .card, .notifications {
    width: 100%;

    @include sm {
      width: 500px;
    }
  }

  .card {
    .media {
      @apply bg-zinc-800;
      height: 285px;

      @media (min-width: 420px) {
        height: 360px;
      }

      @media (min-width: 520px) {
        height: 450px;
      }

      @include sm {
        height: 345px;
      }
    }

    .actions {
      column-gap: 10px;
      display: grid;
      grid-template-columns: repeat(2, 58px);;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
