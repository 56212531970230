@import "src/styles/partials/media";

.wrapper {
  align-items: center;
  display: flex;
  height: var(--footer-height);
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;

  @include sm {
    padding: 0 30px;
  }

  aside {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 16px;
  }

  nav {
    color: #ffffff;
    display: grid;
    grid-column-gap: 6px;
    grid-template-columns: repeat(4, 58px);

    @include sm {
      grid-column-gap: 12px;
    }
  }
}
