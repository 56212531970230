a {
  cursor: pointer;
  text-decoration: none;
}

body {
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6, dl, dd, dt, p, aside, img, picture, video {
  border: 0;
  font-size: 100%;
  font-weight: normal;
  margin: 0;
  padding: 0;
}


/* tailwind start */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* tailwind end */

/* primereact start */
@import "../node_modules/primereact/resources/themes/tailwind-light/theme.css";
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primeicons/primeicons.css";

:root {
  --primary-color: #4e25e1;
  --primary-color-text: #4e25e1;
  --header-height: 24px;
  --footer-height: 96px; /* h-24 */
}

.p-card-title, .p-card-subtitle {
  text-align: center;
}

.p-avatar-circle {
  border-radius: 50%;
}

/* primereact end */

html, body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: var(--font-family);
}